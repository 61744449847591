import React from "react";
// import Corevalue from "../About/Corevalue";
import "./Gallery.css";
import GalleryPage from "./GalleryPage";
// import { Contactseo } from "../SeoContent";
// import { Helmet } from "react-helmet";
// import parse from "html-react-parser";

function Contact() {
  return (
    <>
      {/* <Helmet>{parse(Contactseo.meetafamily)}</Helmet> */}
      {/* <Corevalue /> */}
      <GalleryPage />
    </>
  );
}

export default Contact;
