import React from "react";
import Ourdepartmentbanner from "./Ourdepartmentbanner";
import Corevalue from "../About/Corevalue";
import Ourdepartmentcards from "./Ourdepartmentcards";
function OurDepartment() {
  return (
    <>
      <Ourdepartmentbanner />
      <Corevalue />
      <Ourdepartmentcards />
    </>
  );
}

export default OurDepartment;
