import React from 'react';

const Sitemap = () => {
  return (
    <>
             <urlset xmlns="https://www.sitemaps.org/schemas/sitemap/0.9">
                <url>
                <loc>https://www.neohospital.com/neurology</loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/gastrosciences
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/cardiology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/pulmonology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/dental
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/dermatology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/dietetics
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/ent
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/gynaecology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/clinical-laboratory
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/anaesthesiology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/emergency-medicine
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/clinical-psychology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/nephrology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/neurosurgery
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/orthopaedics
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/opthalmology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/physiotherapy
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/psychiatry
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/neonatology-peadiatrics
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/cosmetic-plastic-surgery
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/cath-lab
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/general-laparoscopic-surgery
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/general-laparoscopic-surgery
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/urology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/radiology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
                </url>
                <url>
                <loc>https://www.neohospital.com/medical-oncology
                </loc>
                <lastmod>2024-04-05</lastmod>
                <changefreq>daily</changefreq>
                <priority>1.00</priority>
             </url>
          </urlset>
    </>
  );
};

export default Sitemap;
