import React from 'react'

import ourspecailty from "../../Assets/Banners/ourspecialty.jpg"
function Ourdepartmentbanner() {
  return (
    <>
      <section>
        <img src={ourspecailty} alt="" srcSet="" className='img-fluid'/>
      </section>
    </>
  );
}

export default Ourdepartmentbanner