import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      setGoUp(window.scrollY > 600);
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  const carouselItems = [
    { id: 1, image: 'imgs.png', title: 'Schedule Your Appointment Online' },
    { id: 2, image: 'imgs.png', title: 'Schedule Your Appointment Online' },
    { id: 3, image: 'imgs.png', title: 'Schedule Your Appointment Online' },
  ];

  return (
    <section className="banner-back-color ">
      <div className="section-container"
        style={{ backgroundImage: 'url(../../Assets/homebanners/1.jpg)' }}
      >
        <div className="row">
          <div className="col-md-12">
            <div id="carouselExampleIndicators" className="carousel slide">
              {/* <ol className="carousel-indicators">
                {carouselItems.map((item, index) => (
                  <li
                    key={item.id}
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                  ></li>
                ))}
              </ol> */}
              <div className="carousel-inner ">
                {carouselItems.map((item, index) => (
                  <div
                    key={item.id}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  // style={{ backgroundImage: `url(../../Assets/homebanners/${item.image})` }}
                  >
                    <div className="carousel-caption d-none d-md-block">
                      <div className="row makeformcenter">
                        <div className="col-md-4">
                          <h3>{item.title}</h3>
                          <div className="form-container ">
                            <form>
                              <div className="mb-3">
                                <label htmlFor={`name-${item.id}`} className="form-label">Name</label>
                                <input type="text" className="form-control" id={`name-${item.id}`} />
                              </div>
                              <div className="mb-3">
                                <label htmlFor={`email-${item.id}`} className="form-label">Email address</label>
                                <input type="email" className="form-control" id={`email-${item.id}`} />
                              </div>
                              <div className="mb-3">
                                <label htmlFor={`message-${item.id}`} className="form-label">Message</label>
                                <textarea className="form-control" id={`message-${item.id}`} rows="3"></textarea>
                              </div>
                              <button type="submit" className="text-appointment-btn">Submit</button>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
              {/* <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </a> */}
            </div>
          </div>
        </div>

        <div className="callbtn">
          <i className="fa fa-phone"></i> 0120-4880000
        </div>
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </div>
      </div>
    </section>
  );
}

export default Hero;
