import React from 'react'
import ourspecailty from "../../Assets/Banners/web-banner2.jpg";
function Doctorsbanner() {
  return (
    <>
        <section>
          <img src={ourspecailty} alt="" srcset="" className="banner-img" />
        </section>
    </>
  );
}

export default Doctorsbanner