const Homeseo = {
  meetafamily:
    '<title>Super Speciality Hospital in Noida | NEO Hospital </title><meta name="title" content="Super Speciality Hospital in Noida | NEO Hospital "/><meta name="description" content="NEO Hospital Noida: Premier Super Speciality Care! Cutting-edge treatments, expert doctors, and compassionate care. Visit us for exceptional health services!"/><meta name="keywords" content="Neo Hospital, Neo Hospital Noida, Super Speciality Hospital in Noida"/><link rel="canonical" href="https://www.neohospital.com"/><meta name="DC.Title" content="Super Speciality Hospital in Noida | NEO Hospital "/><meta name="DC.Subject" content="NEO Hospital Noida: Premier Super Speciality Care! Cutting-edge treatments, expert doctors, and compassionate care. Visit us for exceptional health services!"/><meta property="og:title" content="Super Speciality Hospital in Noida | NEO Hospital "/><meta property="og:description" content="NEO Hospital Noida: Premier Super Speciality Care! Cutting-edge treatments, expert doctors, and compassionate care. Visit us for exceptional health services!"/><meta name="author" content="Neo Hospital"/><meta name="owner" content="Neo Hospital"/><meta name="language" content="en-us"/><meta name="coverage" content="Global"/><meta name="robots" content="INDEX,FOLLOW"/><meta name="GOOGLEBOT" content="INDEX, FOLLOW"/><meta name="doc-type" content="Webpage"/><meta name="revisit-after" content="7 days"/>',
};

const Aboutseo = {
  meetafamily:
    '<title>Best Hospital in Noida | NEO Hospital</title><meta name="title" content="Best Hospital in Noida | NEO Hospital"/><meta name="description" content="Neo Hospital offers comprehensive and personalized services conveniently and holistically. Operating 24*7 with world-class healthcare across various specialties."/><meta name="keywords" content="Best Hospital in Noida"/><link rel="canonical" href="https://www.neohospital.com/about"/><meta name="DC.Title" content="Best Hospital in Noida | NEO Hospital"/><meta name="DC.Subject" content="Neo Hospital offers comprehensive and personalized services conveniently and holistically. Operating 24*7 with world-class healthcare across various specialties."/><meta property="og:title" content="Best Hospital in Noida | NEO Hospital"/><meta property="og:description" content="Neo Hospital offers comprehensive and personalized services conveniently and holistically. Operating 24*7 with world-class healthcare across various specialties."/><meta name="revisit-after" content="7 days"/>',
};

const Specialtyseo = {
  meetafamily:
    "<title>NEO Hospital Specialities</title><meta name='title' content='NEO Hospital Specialities' /><meta name='description' content='Discover NEO Hospital's top-notch specialties! Expert care in cardiology, neurology, orthopedics & more. Heal with the best! Visit now.' /><meta name='keywords' content='Neuro Hospital in Noida , Cardiologist in Noida, ENT Specialist in Noida' /><link rel='canonical' href='https://www.neohospital.com/specialities' /><meta name='DC.Title' content='NEO Hospital Specialities' /><meta name='DC.Subject' content='Discover NEO Hospital's top-notch specialties! Expert care in cardiology, neurology, orthopedics & more. Heal with the best! Visit now.' /><meta property='og:title' content='NEO Hospital Specialities' /><meta property='og:description' content='Discover NEO Hospital's top-notch specialties! Expert care in cardiology, neurology, orthopedics & more. Heal with the best! Visit now.' /><meta name='revisit-after' content='7 days' />",
};

const Doctorseo = {
  meetafamily:
    '<title>Meet Our Doctors | NEO Hospital</title><meta name="title" content="Meet Our Doctors | NEO Hospital"/><meta name="description" content="Meet our expert team of compassionate doctors! Discover the dedicated professionals eager to provide you with top-notch healthcare."/><meta name="keywords" content="Best Hospital in Noida"/><link rel="canonical" href="https://www.neohospital.com/doctors"/><meta name="DC.Title" content="Meet Our Doctors | NEO Hospital"/><meta name="DC.Subject" content="Meet our expert team of compassionate doctors! Discover the dedicated professionals eager to provide you with top-notch healthcare."/><meta property="og:title" content="Meet Our Doctors | NEO Hospital"/><meta property="og:description" content="Meet our expert team of compassionate doctors! Discover the dedicated professionals eager to provide you with top-notch healthcare."/><meta name="revisit-after" content="7 days"/>',
};

const Serviceseo = {
  meetafamily:
    '<title>Patient Services - Good Hospital in Noida</title><meta name="title" content="Patient Services - Good Hospital in Noida"/><meta name="description" content="Experience top-notch patient care at Neo Hospital. Fast appointments, 24/7 support, & compassionate service. Your health is our priority!"/><meta name="keywords" content="Good Hospital in Noida,Best Hospital in Noida"/><link rel="canonical" href="https://www.neohospital.com/services"/><meta name="DC.Title" content="Patient Services - Good Hospital in Noida"/><meta name="DC.Subject" content=""/><meta property="og:title" content="Patient Services - Good Hospital in Noida"/><meta property="" content="Experience top-notch patient care at Neo Hospital. Fast appointments, 24/7 support, & compassionate service. Your health is our priority!"/><meta name="revisit-after" content="7 days"/>',
};

const Contactseo = {
  meetafamily:
    "<title>Contact Us | NEO Hospital</title><meta name='title' content='Contact Us | NEO Hospital' /><meta name='description' content='Get in touch with us for any queries related to Book Appointment with Doctor, Book OPD, Lab Report, Provide feedback & more.' /><meta name='keywords' content='Neuro Hospital in Noida , Cardiologist in Noida, ENT Specialist in Noida' /><link rel='canonical' href='https://www.neohospital.com/contact' /><meta name='DC.Title' content='Contact Us | NEO Hospital' /><meta name='DC.Subject' content='Get in touch with us for any queries related to Book Appointment with Doctor, Book OPD, Lab Report, Provide feedback & more.' /><meta property='og:title' content='Contact Us | NEO Hospital' /><meta property='og:description' content='Get in touch with us for any queries related to Book Appointment with Doctor, Book OPD, Lab Report, Provide feedback & more.' /><meta name='revisit-after' content='7 days' />",
};

const Blogseo = {
  meetafamily:
    "<title>Blogs | NEO Hospital</title><meta name='title' content='Blogs | NEO Hospital' /><meta name='description' content='Get the latest healthcare updates and articles from NEO Hospital, one of the Best Hospital in Noida. Read treatments, symptoms,and many more.' /><meta name='keywords' content='Best Hospital in Noida' /><link rel='canonical' href='https://www.neohospital.com/blog' /><meta name='DC.Title' content='Blogs | NEO Hospital' /><meta name='DC.Subject' content='Get the latest healthcare updates and articles from NEO Hospital, one of the Best Hospital in Noida. Read treatments, symptoms,and many more.' /><meta property='og:title' content='Blogs | NEO Hospital' /><meta property='og:description' content='Get the latest healthcare updates and articles from NEO Hospital, one of the Best Hospital in Noida. Read treatments, symptoms,and many more.' /><meta name='revisit-after' content='7 days' />",
};

export {
  Homeseo,
  Aboutseo,
  Specialtyseo,
  Doctorseo,
  Serviceseo,
  Contactseo,
  Blogseo,
};
// const Homeseo = {
//   title: "Neo hospital Title Page",
//   metatitle: "neo home meta title",
//   metadescription: "neo home meta discription",
//   metakeyword: "home keyword1 , home keyword 2 , home keyword 3",
//   canonical: "canonical",
//   gsv: "O9NzBXm7-53U8SXL6pqSlYIP7H1QDIpjXkLr8M2YZes",
//   dctitle: "dctitle",
//   dcsubject: "dcsubject",
//   ogtitle: "ogtitle",
//   ogdescription: "ogdescription",
// };

// const Aboutseo = {
//   title: "Neo about Title Page",
//   metatitle: "neo about meta title",
//   metadescription: "neo about meta discription",
//   metakeyword: "about keyword1 , about keyword 2 , about keyword 3",
//   canonical: "canonical about",
//   gsv: "O9NzBXm7-53U8SXL6pqSlYIP7H1QDIpjXkLr8M2YZes about",
//   dctitle: "dctitle about",
//   dcsubject: "dcsubject about",
//   ogtitle: "ogtitle about",
//   ogdescription: "ogdescription about",
// };

// const specialtyseo = {
//   title: "Neo speciality Title Page",
//   metatitle: "neo speciality meta title",
//   metadescription: "neo speciality meta discription",
//   metakeyword:
//     "speciality keyword1 , speciality keyword 2 , speciality keyword 3",
//   canonical: "canonical speciality",
//   gsv: "O9NzBXm7-53U8SXL6pqSlYIP7H1QDIpjXkLr8M2YZes speciality",
//   dctitle: "dctitle speciality",
//   dcsubject: "dcsubject speciality",
//   ogtitle: "ogtitle speciality",
//   ogdescription: "ogdescription speciality",
// };

// export {Homeseo , Aboutseo , specialtyseo}
