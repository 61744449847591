import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Profile.css";
import parse from "html-react-parser";
import emailjs from "@emailjs/browser";
import fallbackImage from "../../Assets/manpic.png";


function Profile() {
  const [Neodoctor, setNeodoctor] = useState([]);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [booktime, setBooktime] = useState("");
  const [bookdate, setBookdate] = useState("");
  const [doctorname, setDoctorname] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.neohospital.com/api/adminv2/view-doctors/"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setNeodoctor(data.doctors);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  const { dr } = useParams();
  const doctor = Neodoctor.find((value) => value.drSlug === dr);
  const [selectedDate, setSelectedDate] = useState("");

  if (!doctor) {
    return (
      <div>
        {/* the Doctor not found doctorslug = {doctor} and doctor {dr} */}
        Loading....
      </div>
    );
  }


  const sendEmail = (e) => {
    e.preventDefault();

    // Validate inputs
    if (!name || !number || !email) {
      window.alert("Please fill out all fields.");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      window.alert("Please enter a valid email address.");
      return;
    }

    // Validate phone number format
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(number)) {
      window.alert("Please enter a valid Indian phone number.");
      return;
    }

    const YOUR_SERVICE_ID = "service_mst5kgs";
    const YOUR_TEMPLATE_ID = "template_o5ow29e";
    const YOUR_USER_ID = "L921BkCJFmULbhjBW";

    emailjs
      .sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setName("");
          setNumber("");
          setEmail("");
          setBookdate("");
          setBooktime("");
          setDoctorname("");
          window.alert("Your appointment has been proceed!");
        },
        (error) => {
          console.error("FAILED...", error);
          window.alert("Failed to send appointment. Please try again later.");
        }
      );
  };


  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (selectedDate.getDay() === 0) {
      alert("Doctor is not available on this day");
      setSelectedDate("");
    } else {
      setSelectedDate(e.target.value);
    }
  };
  return (
    <>
      <section className="container">
        <div className="doctorprofile">
          <div className="row">
            <div className="col-md-8">
              <h1 className="pb-3">{doctor.drTitle}</h1>

              <div className="doctordetails">
                <section className="Meetourdoctor">
                  <div>
                    <p>{parse(doctor.drDetail)}</p>
                  </div>
                </section>
              </div>

            </div>

            <div className="col-md-4 shadowdrp">
              <div className="doctorpic">
                <div>
                  <div className="profile-card">
                    <div className="img">
                      {doctor.drImage ? (
                        <img
                          src={`https://api.neohospital.com/uploads/doctors/${doctor.drImage}`}
                          alt={doctor.drTitle}
                        />
                      ) : (
                        <img src={fallbackImage} alt="NEO Hospital Doctors" />
                      )}
                    </div>
                    <div className="about-title">
                      <h2>{doctor.drTitle}</h2>
                      <h5>{doctor.drQualification}</h5>
                      <p>{doctor.drDepartment}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="doctordetails">
                <form onSubmit={sendEmail}>
                  <div className="row">
                    <p>Your appointment will be confirmed within 24 hours after a callback from our team.</p>
                    <div className="col-md-6">
                      <div>
                        <input
                          type="text"
                          placeholder="Name"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <input
                      type="text"
                      placeholder="Phone"
                      className="form-control"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      name="number"
                    />
                  </div>
                  <div className="slotbook p-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p>Book your Slot Date :</p>
                          <input
                            type="text"
                            id="date"
                            // name="date"
                            value={bookdate}
                            onChange={(e) => setBookdate(e.target.value)}
                            name="bdate"
                          // Here you can add min and max props to restrict date range if needed
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <p>Time Slots :</p>
                          <input
                            type="text"
                            id="date"
                            // name="date"
                            value={booktime}
                            onChange={(e) => setBooktime(e.target.value)}
                            name="btime"
                          /><input
                            type="hidden"
                            id="date"
                            // name="date"
                            value={doctor.drTitle}
                            onChange={(e) => setDoctorname(e.target.value)}
                            name="dname"
                          />
                          {/* <select>
                            <option className="text-center">
                              Book Your Slot
                            </option>
                            <option>02:00 PM - 02:15 PM</option>
                            <option>02:15 PM - 02:30 PM</option>
                            <option>02:30 PM - 02:45 PM</option>
                            <option>02:45 PM - 03:00 PM</option>
                            <option>03:00 PM - 03:15 PM</option>
                            <option>03:15 PM - 03:30 PM</option>
                            <option>03:30 PM - 03:45 PM</option>
                            <option>03:45 PM - 04:00 PM</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <textarea />
                  </div>
                  <div>
                    <input
                      type="submit"
                      value="Schedule Appointment"
                      className="btn"
                    />
                  </div>
                </form>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
