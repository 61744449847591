import React from "react";
import { Link } from "react-router-dom";
// import InformationCard from "./InformationCard";
// import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "./Info.css";
import sectionimg from '../../Assets/homebanners/11.png';
// import sectionimg from "../../Assets/index/feedbackpic.jpg";

import Specialty from "./Specialty";
function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <div className="row">
          <div className="col-md-7">
            <h3 className="info-title">
              <span>OUR SPECIALITY DEPARTMENTS</span>
              <div className="services container">
                <div>
                  <Specialty />
                </div>
              </div>
            </h3>
          </div>
          <div className="col-md-5" style={{
            backgroundImage: `url('${sectionimg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'cover',
            backgroundSize: 'cover'
          }}>
            {/* <p className="text-start">
              At Neo Hospital, excellence is our tradition. Explore our
              specialized departments, including Neurology, Cardiology,
              Orthopedics, and Women's Health. Our expert teams are committed to
              providing unparalleled care for your well-being.
            </p> */}
            <div className="callbtndoc">
              <button>Find a Doctor</button><span className="arrowbtndoc"><i class="fa-solid fa-magnifying-glass"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
