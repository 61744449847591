import React from 'react'
// import OurServicebanner from './OurServicebanner';
// import Corevalue from '../About/Corevalue';
import OurInternationPatientcards from './OurInternationPatientcards';
// import { Serviceseo } from "../SeoContent";
// import { Helmet } from "react-helmet";
// import parse from "html-react-parser";   


function OurService() {
  return (
    <>
      {/* <Helmet>{parse(Serviceseo.meetafamily)}</Helmet> */}
      {/* <OurServicebanner /> */}
      {/* <Corevalue /> */}
      <OurInternationPatientcards />
    </>
  );
}

export default OurService
