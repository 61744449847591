import React from 'react'
import contactban from "../../Assets/Banners/contactus.jpg"
function Contactbanner() {
    return (
      <>
        <section id="contact">
          <img src={contactban} alt="" srcset="" className="banner-img" />
        </section>
      </>
    );
}

export default Contactbanner